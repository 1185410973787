import type { Filter } from "@/components/Filters";

import { memo, useEffect, useRef, useState } from "react";

import CambioPage from "@/components/base/cards/CambioPage";
import IconButton from "@/components/Button/IconButton";
import DateRangePicker from "@/components/DateRangePicker";
import Filters, { isFilterComplete } from "@/components/Filters";
import HovercardToggle from "@/components/HovercardToggle";
import { PortalContext } from "@/components/Portal";
import SelectableList from "@/components/SelectableList";

import { useDashboardPdfDownload } from "@/js/hooks/useDashboardPdfDownload";
import useLocalStorage from "@/js/hooks/useLocalStorage";
import useRouter from "@/js/hooks/useRouter";
import { track } from "@/js/services/mixpanel";

import { useAppContext } from "@/layouts/AppLayout/AppContext";

import DashboardContent from "./DashboardContent";
import AnnualizedDateDropdown from "./DashboardContent/AnnualizedDateDropdown";
import { getMetricDateRange } from "./DashboardContent/utils";

export default memo(function DashboardPage() {
  const {
    featureConfigurations,
    defaultMetricsDateRange,
    isInPortfolioContext,
    organizationName,
    organizationId,
    organizationToken,
  } = useAppContext();

  const [savedFilters, setSavedFilters, removeSavedFilters] = useLocalStorage(
    isInPortfolioContext ? "savedFiltersPortfolio" : "savedFiltersSubportfolio",
    [],
  );
  // the difference between saved filters and state filters is that state filters can be incomplete.
  // ie, they can have a key and operator but no values. saved filters must be complete.
  const [filters, setFilters] = useState<Filter[]>(savedFilters);

  const router = useRouter();
  const headerRef = useRef<HTMLElement>();
  // this range will use start_date/end_date query params if they exist, otherwise they
  // will be equal to whatever the default range endpoint returned. They are ISO strings
  const metricDateRange = getMetricDateRange(router.query, defaultMetricsDateRange, {
    featureConfigurations,
  });

  const onChangeMetricDateRange = (range: [string, string]) =>
    router.push({
      query: {
        ...router.query,
        start_date: range[0],
        end_date: range[1],
      },
    });

  /**
   * This is auto-saving while we use local storage to save filters.
   */
  useEffect(() => {
    const completeFilters = filters.filter(isFilterComplete);

    if (completeFilters.length) {
      setSavedFilters(completeFilters, { silent: true });
    } else if (!filters.length && savedFilters.length) {
      removeSavedFilters({ silent: true });
    }
  }, [JSON.stringify(filters)]);

  const { downloadPdf } = useDashboardPdfDownload();

  return (
    <CambioPage title="Dashboard">
      <div className="DashboardPage">
        <PortalContext.Provider value={{ parent: headerRef }}>
          <header ref={headerRef}>
            <div>
              <h1>Dashboard</h1>
              <div>
                {featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED ?
                  <AnnualizedDateDropdown
                    dateRange={metricDateRange}
                    onChangeMetricDateRange={onChangeMetricDateRange}
                  />
                : <DateRangePicker
                    dateRange={metricDateRange}
                    onChangeDateRange={onChangeMetricDateRange}
                  />
                }
                {featureConfigurations.TEMP_NEW_DASHBOARD_PDF_ENABLED ?
                  <HovercardToggle
                    alignment="right"
                    contents={() => (
                      <SelectableList
                        items={[
                          // Raw data export is disabled when the new dashboard PDF is enabled until we update the export to include filters
                          {
                            key: "export-dashboard",
                            display: "Export dashboard .pdf",
                            onClick: async () => {
                              track("Properties - Export Dashboard PDF Clicked");

                              if (featureConfigurations.TEMP_NEW_DASHBOARD_PDF_ENABLED) {
                                downloadPdf({
                                  spaceToken: undefined,
                                  spaceId: undefined,
                                  organizationId: organizationId,
                                  organizationName,
                                  spaceName: undefined,
                                  dateRange: metricDateRange,
                                  organizationToken,
                                  isTotalPortfolio: isInPortfolioContext,
                                  filters,
                                });
                              }
                            },
                          },
                        ]}
                      />
                    )}
                  >
                    <IconButton icon="kebab" size="large" />
                  </HovercardToggle>
                : null}
              </div>
            </div>
            {featureConfigurations.ENABLED_PROPERTY_FILTERS?.length ?
              <Filters filters={filters} onChangeFilters={setFilters} />
            : null}
          </header>
        </PortalContext.Provider>
        <DashboardContent dateRange={metricDateRange} filters={filters} />
      </div>
    </CambioPage>
  );
});
