import { IconsType } from "../SvgIcon";

export const AssetClasses: Record<string, { display: string; icon: IconsType }> = {
  "Banking / Financial Services": { display: "Banking & Financial Services", icon: "dollar" },
  Education: { display: "Education", icon: "education" },
  "Entertainment / Public Assembly": {
    display: "Entertainment & Public Assembly",
    icon: "entertainment",
  },
  "Food Sales & Service": { display: "Food Sales & Service", icon: "dining" },
  Healthcare: { display: "Healthcare", icon: "healthcare" },
  "Lodging / Residential": { display: "Lodging & Residential", icon: "home" },
  "Manufacturing / Industrial": { display: "Manufacturing & Industrial", icon: "manufacturing" },
  "Mixed Use": { display: "Mixed Use", icon: "mixed-use" },
  Office: { display: "Office", icon: "briefcase" },
  Parking: { display: "Parking", icon: "car" },
  "Public Services": { display: "Public Services", icon: "fund" },
  "Religious Worship": { display: "Religious Worship", icon: "church" },
  Retail: { display: "Retail", icon: "retail" },
  "Technology / Science": { display: "Technology & Science", icon: "tech" },
  Services: { display: "Services", icon: "hospitality" },
  Utility: { display: "Utility", icon: "utility" },
  "Warehouse / Storage": { display: "Warehouse & Storage", icon: "warehouse" },
  Other: { display: "Other", icon: "buildings" },
};
