import type { FeatureFlags } from "@/js/models/types";
import type { ReactElement } from "react";
import type { SelectableListItem } from "../SelectableList";
import type { IconsType } from "../SvgIcon";

import { Tooltip } from "antd";
import { Fragment, useLayoutEffect } from "react";
import { ExecutorFunction, useResources } from "resourcerer";

import Button from "@/components/Button";
import HovercardToggle from "@/components/HovercardToggle";

import { getLoadingCell } from "@/js/utils/partials";

import { useAppContext } from "@/layouts/AppLayout/AppContext";

import MultiSelect, { EMPTY_DISPLAY, Option } from "../Select/MultiSelect";
import SelectableList from "../SelectableList";
import SvgIcon from "../SvgIcon";
import { AssetClasses } from "./constants";

type AvailableFilters = Record<FilterTypes, SelectableListItem & { options: Option<string>[] }>;

export type FilterTypes = "partner_tokens" | "asset_class_broad_categories";
type FilterConfig = {
  key: FilterTypes;
  display: string;
  placeholder: string;
  icon: IconsType;
  plural: string;
  requiresFeature?: keyof FeatureFlags;
  prompt?: string;
};

const FilterConfig: Record<FilterTypes, FilterConfig> = {
  asset_class_broad_categories: {
    key: "asset_class_broad_categories",
    display: "Asset Class",
    icon: "buildings",
    placeholder: "Select types",
    plural: "asset classes",
  },
  partner_tokens: {
    key: "partner_tokens",
    display: "Partners",
    icon: "users-three",
    placeholder: "Select names",
    plural: "partners",
  },
};

export interface Filter {
  key: FilterTypes;
  operator: "is";
  values?: string[];
}

interface FilterProps {
  /** These are any saved filters to display, separate from the set of available filters, which we fetch */
  filters: Filter[];
  onChangeFilters: (filters: Filter[]) => void;
}

const getResources: ExecutorFunction<"propertyFilters", { orgToken: string }> = ({ orgToken }) => ({
  propertyFilters: { path: { orgToken } },
});

/**
 * These are a set of items for our dashboards to filter results by. Each one has their own set of
 * items to pick and choose.
 */
export default function Filters({ filters = [], onChangeFilters }: FilterProps) {
  const { organizationToken } = useAppContext();
  const { propertyFiltersCollection, hasLoaded, isLoading } = useResources(getResources, {
    orgToken: organizationToken,
  });
  const onRemoveFilter = (filterKey: string) =>
    onChangeFilters(filters.filter(({ key }) => key !== filterKey));

  const availableFilters: AvailableFilters = propertyFiltersCollection.toJSON().reduce(
    (memo, { filter_key, display_text, options }) =>
      options.length ?
        Object.assign(memo, {
          [filter_key]: {
            key: filter_key,
            display: display_text,
            options: options.map(({ identifier, display_text, description }) => ({
              value: identifier,
              display: display_text,
              secondaryDisplay: description,
              ...(filter_key === "asset_class_broad_categories" ? AssetClasses[identifier] : {}),
            })),
          },
        })
      : memo,
    {} as AvailableFilters,
  );

  const maybeWithTooltip = (children: ReactElement, text: string, shouldShowTooltip: boolean) =>
    shouldShowTooltip ?
      <Tooltip arrow={false} title={text}>
        <span>{children}</span>
      </Tooltip>
    : children;

  /**
   * Make sure any saved filter values still exist in the data set; if not, omit them.
   * If that makes a filter have no values, remove the whole filter.
   */
  useLayoutEffect(() => {
    if (hasLoaded && filters.length) {
      const displayableFilters = filters
        .map((filter: Filter) => {
          const savedValues = (filter.values || []).filter(
            (val) => availableFilters[filter.key]?.options.map(({ value }) => value).includes(val),
          );

          return !savedValues.length ? undefined : { ...filter, values: savedValues };
        })
        .filter(Boolean);

      if (
        filters.length !== displayableFilters.length ||
        filters.some(
          ({ key, values = [] }) =>
            values.length !== displayableFilters.find((filter) => filter.key === key).values.length,
        )
      ) {
        onChangeFilters(displayableFilters);
      }
    }
  }, [hasLoaded]);

  return (
    <div className="Filters">
      {filters.map((filter, i) => {
        if (!FilterConfig[filter.key]) {
          return null;
        }

        const filterConfig = {
          ...availableFilters[filter.key],
          ...FilterConfig[filter.key],
        };

        return (
          <MultiSelect
            key={filter.key}
            initiallyOpen={!filter.values?.length}
            options={filterConfig.options}
            // if we don't select anything new, then nothing will have changed and onSelect won't
            // get called. in that case, onClose will help us remove the filter
            onClose={(values) => (!values.length ? onRemoveFilter(filter.key) : null)}
            onSelect={(values) =>
              values.length ?
                onChangeFilters(
                  filters.map((_filter) =>
                    _filter.key !== filter.key ?
                      _filter
                    : { ..._filter, values: values as string[] },
                  ),
                )
              : onRemoveFilter(filter.key)
            }
            placeholder={filterConfig.placeholder}
            promptText={filterConfig.prompt}
            value={filter.values}
          >
            {({ text }) =>
              maybeWithTooltip(
                <Button icon={filterConfig.icon}>
                  <strong>{filterConfig.display}:</strong>
                  {text ?
                    // while loading, swap out any missing displays with the loading cell
                    text
                      .split("\xa0")
                      .map((el, i) => (
                        <Fragment key={i}>
                          {isLoading && el.startsWith(EMPTY_DISPLAY) ?
                            getLoadingCell()
                          : `${el}\xa0`}
                        </Fragment>
                      ))
                  : filterConfig.placeholder}
                  <span onClick={() => onRemoveFilter(filter.key)}>
                    <SvgIcon name="x" />
                  </span>
                </Button>,
                `View selected ${filterConfig.plural}`,
                filter.values?.length > 2,
              )
            }
          </MultiSelect>
        );
      })}
      {/** only show the add filter button if there is a filter to add */}
      {(
        !Object.values(availableFilters).length ||
        filters.length < Object.values(availableFilters).length
      ) ?
        <HovercardToggle
          // availableFilters should never be empty once we have asset class and geography filters,
          // but it might be for partners, so just show it as disabled
          disabled={!Object.values(availableFilters).length}
          contents={() => (
            <SelectableList
              items={Object.values(availableFilters).filter(
                ({ key }) => !filters.map((filter) => filter.key).includes(key as FilterTypes),
              )}
              onClick={(item, evt) => {
                evt.stopPropagation();
                onChangeFilters(filters.concat({ key: item.key as FilterTypes, operator: "is" }));
              }}
            />
          )}
        >
          <Button flavor="link" icon="plus">
            Add Filter
          </Button>
        </HovercardToggle>
      : null}
    </div>
  );
}

export function isFilterComplete({ key, operator, values = [] }: Filter) {
  return !!(key && operator && values.length);
}
